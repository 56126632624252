
import { Component, Prop, Mixins } from 'vue-property-decorator';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';

@Component({})
export default class Dropdown extends Mixins(GlobalMixin) {
    @Prop({ default: [] })
    items?: Array<{ key: any, value: number, textContent: string }>;

    private selectedItem?: any = null;

    mounted (): void {
        this.$nextTick(() => {
            document.addEventListener('click', this.handleDocumentClick.bind(this));
        });
    }

    handleDocumentClick (): void {
        this.hideDropdownList(this.$el as HTMLElement);
    }

    handleDropdownClick (): void {
        this.playSound('ClickButtons');
        this.hideAllDropdowns();

        const dropdownList = this.$refs.dropdownList;
        if (dropdownList) {
            this.showDropdownList();
        }
    }

    handleDropdownListItemClick (item: any): void {
        this.playSound('ClickButtons');
        this.selectedItem = item;
        this.$emit('input', this.selectedItem);
    }

    showDropdownList (): void {
        const dropdownElement = this.$el as HTMLElement;
        dropdownElement.classList.add('active');
    }

    hideDropdownList (dropdownElement: HTMLElement): void {
        dropdownElement && dropdownElement.classList.remove('active');
    }

    hideAllDropdowns (): void {
        const dropdowns = document.querySelectorAll('.dropdown');
        for (let dropdownIndex = 0; dropdownIndex < dropdowns.length; dropdownIndex++) {
            const dropdown = dropdowns[dropdownIndex] as HTMLElement;
            this.hideDropdownList(dropdown);
        }
    }

    get itemsFiltered () {
        return this!.items!.filter(item => item.value !== 0);
    }

    get selectedItemValue () {
        if (this.selectedItem) {
            return this.selectedItem.textContent;
        }

        if (this.items && this.items.length) {
            return this.items[0].textContent;
        }
    }

    set selectedItemValue (item: any) {
        this.selectedItem = item;
    }

    get currency (): string {
        return this.$store.state.locale.currency;
    }
}
