
import Popup from './../shared/Popup/Popup.vue';
import Error from './../popupContents/error/Error.vue';
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

import GameUIContainer from './GameUIContainer.vue';
import CommonEventEmitter from '../../event-emitter/CommonEventEmitter';

@Component({
    components: {
        GameUIContainer,
        Popup,
        Error
    }
})
export default class GameContainer extends Vue {
    mounted () {
        this.$nextTick(() => {
            CommonEventEmitter.announceGameLoaded();
        });
    }

    get isErrorPopupActive (): boolean {
        return this.$store.state.hardError;
    }

    get hardErrorMessage (): boolean {
        return this.$store.state.hardErrorMessage;
    }
}
