
import { Component, Mixins } from 'vue-property-decorator';

import PaytableSecondSection from './paytableSections/PaytableSecondSection.vue';

import PaytableSection from './paytableSections/PaytableSection.vue';
import FeaturesSection from './paytableSections/FeaturesSection.vue';
import WinLinesSection from './paytableSections/WinLinesSection.vue';
import SymbolsSection from './paytableSections/SymbolsSection.vue';
import BuyFeatureSection from './paytableSections/BuyFeatureSection.vue';

import PaytableStrokeTitle from './../paytable/PaytableStrokeTitle.vue';

import PrimaryButton from './../shared/Buttons/PrimaryButton.vue';
import Icon from './../shared/Icons/Icon.vue';

import GlobalMixin from '../../mixins/GlobalMixin.vue';
import { EventBus } from '../../utils/EventBus';

@Component({
    components: {
        PaytableSection,
        FeaturesSection,
        PrimaryButton,
        WinLinesSection,
        PaytableStrokeTitle,
        SymbolsSection,
        BuyFeatureSection,
        PaytableSecondSection,
        Icon
    }
})
export default class PaytableDesktop extends Mixins(GlobalMixin) {
    private paytableTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_paytable.png`;
    private featuresTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_features.png`;
    private symbolsTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_symbols.png`;
    private winLinesTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_paylines.png`;
    private paytableBtn: string = `./assets/${this.lang}/paytable/texts/paytable_play.png`;

    swiperOption = {
        init: false,
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        allowTouchMove: false,
        loop: true,
        speed: 800,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    };

    mounted () {
        this.initSwiper();
        this.$nextTick(() => {
            this.updateSwiper();
            this.initEvents();
        });
    }
    get lang (): string {
        return window.lang;
    }
    get buyFeatureDisabled (): boolean {
        return window.disableBuyFeature;
    }

    private initEvents (): void {
        if (this.$refs.swiperArrowButtonPrev) {
            (this.$refs.swiperArrowButtonPrev as HTMLElement).addEventListener('click', () => this.playSound('ClickButtons'));
        }

        if (this.$refs.swiperArrowButtonNext) {
            (this.$refs.swiperArrowButtonNext as HTMLElement).addEventListener('click', () => this.playSound('ClickButtons'));
        }
    }

    private handlePlayButtonClick () {
        this.$store.dispatch('paytable/hidePaytable');
        this.playSound('ClickButtons');
        this.isJurisdictionForceActive && EventBus.$emit('RESUME_RAF', { type: 'RESUME_RAF' });
        this.isJurisdictionForceActive && EventBus.$emit('STOP_SOUNDS', { type: 'STOP_SOUNDS', value: { action: 'PAUSE', value: false } });
    }

    private handleSwiperComponentReady () {
        this.updateSwiper();
    }

    private updateSwiper () {
        if (this.swiper) {
            setTimeout(() => {
                this.swiper.update();
            }, 100);
        }
    }

    private initSwiper () {
        if (this.swiper) {
            setTimeout(() => {
                this.swiper.init();
            }, 100);
        }
    }

    get swiper () {
        return (this.$refs.mySwiper as any).swiper || null;
    }
    get isJurisdictionForceActive (): boolean {
        return (window.jurisdiction === 'SE' || window.jurisdiction === 'DK');
    }
}
