import { render, staticRenderFns } from "./GameUnfinished.vue?vue&type=template&id=92f57320&scoped=true"
import script from "./GameUnfinished.vue?vue&type=script&lang=ts"
export * from "./GameUnfinished.vue?vue&type=script&lang=ts"
import style0 from "./GameUnfinished.vue?vue&type=style&index=0&id=92f57320&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f57320",
  null
  
)

export default component.exports