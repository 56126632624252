import { ActionContext } from 'vuex';
import { IStoreState } from '@/store/IStoreState';

import { IAutoplayState, IAutoplayActions, IAutoplayMutations, IAutoplayModule, IFields } from './contracts/autoplay';

const state: IAutoplayState = {
    spinsLeft: 0,
    isActive: false,
    isVisible: true,
    autoplayRounds: [5, 10, 25, 50, 100],
    autoplayDisable: false,
    fields: {
        totalSpins: 10,
        limitLoss: 0,
        singleWinLimit: 0
    },
    totalSpins: [
        { key: 0, value: 10, textContent: '10' },
        { key: 1, value: 20, textContent: '20' },
        { key: 2, value: 30, textContent: '30' },
        { key: 3, value: 50, textContent: '50' },
        { key: 5, value: 0, textContent: '0' },
        { key: 6, value: 0, textContent: '0' },
        { key: 7, value: 0, textContent: '0' },
        { key: 8, value: 0, textContent: '0' }
    ],
    autoplayInfiniteRounds: 100000000,
    lossLimit: [
        { key: 1, initValue: 10, value: 10, textContent: '10' },
        { key: 2, initValue: 20, value: 20, textContent: '20' },
        { key: 3, initValue: 30, value: 30, textContent: '30' },
        { key: 4, initValue: 50, value: 50, textContent: '50' },
        { key: 5, initValue: 100, value: 100, textContent: '100' },
        { key: 6, initValue: 200, value: 200, textContent: '200' },
        { key: 7, initValue: 500, value: 500, textContent: '500' },
        { key: 8, initValue: 1000, value: 1000, textContent: '1000' },
        { key: 9, initValue: 5000, value: 5000, textContent: '5000' },
        { key: 10, initValue: 10000, value: 10000, textContent: '10000' }
    ],
    singleWinLimit: [
        { key: 0, initValue: 0, value: 0, textContent: '-' },
        { key: 1, initValue: 10, value: 10, textContent: '10' },
        { key: 2, initValue: 20, value: 20, textContent: '20' },
        { key: 3, initValue: 30, value: 30, textContent: '30' },
        { key: 4, initValue: 50, value: 50, textContent: '50' },
        { key: 5, initValue: 100, value: 100, textContent: '100' },
        { key: 6, initValue: 200, value: 200, textContent: '200' },
        { key: 7, initValue: 500, value: 500, textContent: '500' },
        { key: 8, initValue: 1000, value: 1000, textContent: '1000' },
        { key: 9, initValue: 5000, value: 5000, textContent: '5000' }
    ]
};

const actions: IAutoplayActions = {
    setSpinsLeft ({ commit }: ActionContext<IAutoplayState, IStoreState>, spinsLeft: number): void {
        commit('setSpinsLeft', spinsLeft);
    },
    setActive ({ commit }: ActionContext<IAutoplayState, IStoreState>, value: boolean): void {
        commit('setActive', value);
    },
    setRounds ({ commit }: ActionContext<IAutoplayState, IStoreState>, rounds: number[]): void {
        commit('setRounds', rounds);
    },
    setDisable ({ commit }: ActionContext<IAutoplayState, IStoreState>, autoplayDisable: boolean): void {
        commit('setDisable', autoplayDisable);
    },
    setFields ({ commit }: ActionContext<IAutoplayState, IStoreState>, fields: IFields): void {
        commit('setFields', fields);
    }
};

const mutations: IAutoplayMutations = {
    setSpinsLeft (state: IAutoplayState, spinsLeft: number): void {
        state.spinsLeft = spinsLeft;
    },
    setActive (state: IAutoplayState, value: boolean): void {
        state.isActive = value;
    },
    setRounds (state: IAutoplayState, rounds: number[]): void {
        state.autoplayRounds = rounds;
        rounds.forEach((round: number, indx: number) => {
            state.totalSpins[indx].key = indx;
            state.totalSpins[indx].value = round;
            state.totalSpins[indx].textContent = round.toString();
            if (window.autoplayInfinite && indx === rounds.length - 1) {
                state.totalSpins[indx + 1].key = indx + 1;
                state.totalSpins[indx + 1].value = state.autoplayInfiniteRounds;
                state.totalSpins[indx + 1].textContent = '∞';
            }
        });
    },
    setDisable (state: IAutoplayState, autoplayDisable: boolean): void {
        state.autoplayDisable = autoplayDisable;
    },
    setFields (state: IAutoplayState, fields: IFields): void {
        state.fields = { ...state.fields, ...fields };
    }
};

const autoplay: IAutoplayModule = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default autoplay;
