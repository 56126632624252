
import { Component, Mixins } from 'vue-property-decorator';
import { EventBus } from '../../../utils/EventBus';

import Icon from './../Icons/Icon.vue';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';
import CommonEventEmitter from '../../../event-emitter/CommonEventEmitter';

@Component({
    components: {
        Icon
    }
})
export default class Bet extends Mixins(GlobalMixin) {
    betOptionsIsActive: boolean = false;
    strokeImageSource = `./assets/common/icons/stroke.png`;
    betSource = `./assets/${window.lang}/ui/texts/gameControl_bet.png`;

    mounted () {
        document.addEventListener('click', this.handleDocumentClick.bind(this));
    }

    handleDocumentClick () {
        this.betOptionsIsActive = false;
    }

    handleBetPanelClick (): void {
        this.playSound('ClickButtons');
        if (!this.controlsIsDisabled) {
            this.betOptionsIsActive = !this.betOptionsIsActive;
        }
    }

    handleBetOptionClick (clickedBetOption: number): void {
        if (!this.controlsIsDisabled) {
            this.playSound('ClickButtons');
            this.$store.dispatch('stake/setValue', clickedBetOption);
            CommonEventEmitter.announceStakeChanged({
                stake: this.stakeValue,
                position: this.stakePosition
            });
            this.betOptionsIsActive = false;
        }
    }

    get stakeValue (): number {
        return this.$store.state.stake.value;
    }

    get stakePosition (): number {
        return this.$store.state.stake.stakePosition;
    }

    get controlsIsDisabled (): boolean {
        return this.$store.state.controlsIsDisabled;
    }

    get betOptions (): number[] {
        return this.$store.state.stake.steps;
    }

    get currency (): string {
        return this.$store.state.locale.currency;
    }
}
