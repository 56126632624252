

import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';
import CloseButton from './CloseButton.vue';
import ProgressBar from './ProgressBar.vue';
import GlobalMixin from '../../mixins/GlobalMixin.vue';
import CommonEventEmitter from '../../event-emitter/CommonEventEmitter';
import { EVENTS } from '../../config/constants';
import anime from 'animejs';

@Component({
    components: {
        CloseButton,
        ProgressBar
    }
})
export default class LoadingScreen extends Mixins(GlobalMixin) {
    @Prop({ default: true })
    LoadingScreenIsShown!: boolean;

    @Prop({ default: 100 })
    width!: number;

    @Prop({ default: 100 })
    height!: number;

    percent: number = 0;
    sourceSbLogo: string = `./assets/common/loading/silverback_logo.png`;
    sourceRelaxLogo: string = `./assets/common/loading/relax_logo.png`;
    loadingTxt: string = `./assets/${window.lang}/ui/texts/ui_loading.png`;

    dots: any[] = [];
    dotsActiveIndex: number = 0;
    mainDotsActiveIndex: number = 3;

    backgroundLoadingScreen: string = this.getURLBasedOnDevice(`./assets/common/loading/{{device}}/loading_image.jpg`);

    mainDots: string = this.getURLBasedOnDevice('./assets/common/loading/{{device}}/dots04.png');
    mainDots2: string = this.getURLBasedOnDevice('./assets/common/loading/{{device}}/dots05.png');

    firstDots: string = this.getURLBasedOnDevice('./assets/common/loading/{{device}}/dots01.png');
    secondDots: string = this.getURLBasedOnDevice('./assets/common/loading/{{device}}/dots02.png');
    thirdDots: string = this.getURLBasedOnDevice('./assets/common/loading/{{device}}/dots03.png');

    mounted () {
        this.$nextTick(() => {
            EventBus.$on(EVENTS.LOAD_PROGRESS_RESOURCES, this.showProgress);
            EventBus.$on(EVENTS.RESOURCES_READY, this.enableCloseButton);

            // this.animateDots(); // да се разкоментира когато слагам бликовете на логото
        });
    }

    animateDots (): void {
        this.dots[0] = this.$refs.firstDots;
        this.dots[1] = this.$refs.secondDots;
        this.dots[2] = this.$refs.thirdDots;

        this.dots[3] = this.$refs.mainDots;
        this.dots[4] = this.$refs.mainDots2;

        this.dotsAnimation(this.dots[this.dotsActiveIndex]);
        this.mainDotsAnimation(this.dots[this.mainDotsActiveIndex]);
    }

    mainDotsAnimation (dots: Vue | Element | Vue[] | Element[]): void {
        if (!this.dots.length) {
            return;
        }

        anime({
            targets: dots,
            duration: 1000,
            opacity: 1,
            direction: 'alternate',
            easing: 'linear',
            delay: 0,
            complete: () => {
                if (this.mainDotsActiveIndex >= 4) {
                    this.mainDotsActiveIndex = 2;
                }

                this.mainDotsActiveIndex++;
                this.mainDotsAnimation(this.dots[this.mainDotsActiveIndex]);
            }
        });
    }

    dotsAnimation (dots: Vue | Element | Vue[] | Element[]): void {
        if (!this.dots.length) {
            return;
        }

        anime({
            targets: dots,
            duration: 900,
            opacity: 1,
            direction: 'alternate',
            easing: 'linear',
            delay: 0,
            complete: () => {
                if (this.dotsActiveIndex >= 2) {
                    this.dotsActiveIndex = -1;
                }

                this.dotsActiveIndex++;
                this.dotsAnimation(this.dots[this.dotsActiveIndex]);
            }
        });
    }

    showProgress ({ value }: { value: number }): void {
        this.percent = value;
    }

    enableCloseButton (): void {
        this.$store.dispatch('closeButton/makeCloseButtonActive');
    }

    handleCloseButtonClick (): void {
        CommonEventEmitter.announceMainScreenReady();
        this.$store.dispatch('loadingScreen/LoadingScreenIsShown');

        this.playSound('ClickStartButton', {
            volume: 1
        });
    }

    get closeButtonIsActive (): boolean {
        return this.$store.state.closeButton.closeButtonIsActive;
    }

    get isMobile (): boolean {
        return window.deviceInfo.isMobile;
    }

    beforeDestroy () {
        anime.remove(this.dots);
        this.dots = [];
    }
}

