
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS } from '../../../config/constants';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';
import { IPromotionalFreeSpins } from '../../../store/modules/slot/contracts/promotionalFreeSpins';

@Component({
    components: {}
})
export default class Informational extends Mixins(GlobalMixin) {
    wid: string = window.deviceInfo.isMobile ? '60%' : '46%';
    @Prop({ default: '' })
    content!: string;
    continueBtn: string = `./assets/${window.lang}/ui/texts/ui_continue.png`;
    wonAmount: string = `./assets/${window.lang}/settings/texts/settings_unfinished_win.png`;

    handleInformationalPopup () {
        if (this.promotionalFreeSpins) {
            this.$store.dispatch('promotionalFreeSpins/setFreeSpins', null);
        }

        this.$store.dispatch('popup/hideInformationalPopup');
        this.playSound('ClickButtons');
    }

    get promotionalFreeSpins (): IPromotionalFreeSpins {
        return this.$store.state.promotionalFreeSpins.promotionalFreeSpins;
    }

    get promotionalFreeSpinsTotalWin (): any {
        return this.promotionalFreeSpins.win;
    }
}
