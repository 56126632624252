
import { Component, Mixins } from 'vue-property-decorator';

import translations from './translations';

import HelpImage from './HelpImage.vue';
import GlobalMixin from '../../mixins/GlobalMixin.vue';
import Icon from './../shared/Icons/Icon.vue';

import { EventBus } from '../../utils/EventBus';

@Component({
    components: {
        HelpImage,
        Icon
    }
})
export default class Help extends Mixins(GlobalMixin) {
    lastUpdateString = translations.last_update[window.lang || 'en_US'] || 'last update:';
    gameVersionString = translations.game_version[window.lang || 'en_US'] || 'game version:';

    lastUpdateDate = new Date(2024, 8, 30).toLocaleDateString();
    closeButton = `./assets/common/paytable/close_button_normal.png`;
    closeButtonHover = `./assets/common/paytable/close_button_active.png`;
    paytableOrnaments = `./assets/common/paytable/ornaments.png`;
    paytableLogo = `./assets/common/paytable/logo.png`;
    paytableTextHeader1 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_title.png`;
    rulesText1 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_1.png`;
    rulesText2 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_2.png`;
    rulesText3 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_3.png`;
    rulesText4 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_4.png`;
    rulesText5 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_5.png`;
    // rulesText7 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_7.png`;

    mounted () {
        this.$nextTick(() => this.animateContent());
    }

    hoverOver () {
        (this.$refs.closeButton as HTMLElement).style.backgroundImage = `url(${this.closeButtonHover})`;
    }

    hoverOut () {
        (this.$refs.closeButton as HTMLElement).style.backgroundImage = `url(${this.closeButton})`;
    }

    handleCloseButtonClick () {
        this.$store.dispatch('rules/hideRules');
        this.playSound('ClickButtons');
        this.isJurisdictionForceActive && EventBus.$emit('RESUME_RAF', { type: 'RESUME_RAF' });
        this.isJurisdictionForceActive && EventBus.$emit('STOP_SOUNDS', { type: 'STOP_SOUNDS', value: { action: 'PAUSE', value: false } });
    }

    get isJurisdictionForceActive (): boolean {
        return (window.jurisdiction === 'SE' || window.jurisdiction === 'DK');
    }
    get autoplayDisable (): boolean {
        return this.$store.state.autoplay.autoplayDisable;
    }
    private animateContent (): void {
        setTimeout(() => {
            if (this.$refs.rulesContent) {
                (this.$refs.rulesContent as HTMLElement).classList.add('is-animated');
            }
        }, 300);
    }
}
