
import { Component, Mixins } from 'vue-property-decorator';

import Icon from './../shared/Icons/Icon.vue';
import PaytableImage from './PaytableImage.vue';
import PaytableTitle from './PaytableTitle.vue';
import PaytableSymbols from './paytableSections/PaytableSymbols.vue';
import PaytableFeatures from './paytableSections/PaytableFeatures.vue';
import BuyFeature from './paytableSections/BuyFeature.vue';

import PaytableStrokeTitle from './PaytableStrokeTitle.vue';

import GlobalMixin from '../../mixins/GlobalMixin.vue';
import { EventBus } from '../../utils/EventBus';

@Component({
    components: {
        PaytableTitle,
        PaytableImage,
        PaytableSymbols,
        PaytableFeatures,
        PaytableStrokeTitle,
        BuyFeature,
        Icon
    }
})
export default class Paytable extends Mixins(GlobalMixin) {
    closeButton = `./assets/common/paytable/close_button_normal.png`;
    closeButtonHover = `./assets/common/paytable/close_button_active.png`;

    private paytableLogo = `./assets/common/paytable/logo.png`;

    private paytableTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_paytable.png`;
    private featuresTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_features.png`;
    private symbolsTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_symbols.png`;
    private winLinesTitle: string = `./assets/${this.lang}/paytable/texts/game_rules_paylines.png`;
    private paytableRTP: string = window.rtp !== 95 ? `./assets/${window.lang}/paytable/texts/game_rules_rule8_${window.rtp}.png` : `./assets/${window.lang}/paytable/texts/game_rules_rule8.png`;
    private paytablePaylines: string = './assets/common/paytable/dots.png';

    mounted () {
        this.$nextTick(() => this.animateContent());
    }

    handleCloseButtonClick () {
        this.$store.dispatch('paytable/hidePaytable');
        this.playSound('ClickButtons');
        this.isJurisdictionForceActive && EventBus.$emit('RESUME_RAF', { type: 'RESUME_RAF' });
        this.isJurisdictionForceActive && EventBus.$emit('STOP_SOUNDS', { type: 'STOP_SOUNDS', value: { action: 'PAUSE', value: false } });
    }

    get isJurisdictionForceActive (): boolean {
        return (window.jurisdiction === 'SE' || window.jurisdiction === 'DK');
    }

    get lang (): string {
        return window.lang;
    }
    get buyFeatureDisabled (): boolean {
        return window.disableBuyFeature;
    }

    private animateContent (): void {
        setTimeout(() => {
            if (this.$refs.paytableContent) {
                (this.$refs.paytableContent as HTMLElement).classList.add('is-animated');
            }
        }, 300);
    }
}
