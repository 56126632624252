
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { EventBus } from '../../../utils/EventBus';

import PrimaryButton from './../../shared/Buttons/PrimaryButton.vue';
import Bet from './../../shared/Bet/Bet.vue';
import Icon from './../../shared/Icons/Icon.vue';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';
import CommonEventEmitter from '../../../event-emitter/CommonEventEmitter';
import { EVENTS } from '../../../config/constants';

@Component({
    components: {
        Icon,
        PrimaryButton,
        Bet
    },
    mixins: [GlobalMixin]
})
export default class Controls extends Mixins(GlobalMixin) {
    private isAutoplayActive: boolean = false;
    private UITurboText: string = `./assets/${window.lang}/ui/texts/gameControl_turbo.png`;
    private UIAutoText: string = `./assets/${window.lang}/ui/texts/gameControl_auto.png`;
    mounted () {
        this.$nextTick(() => {
            EventBus.$on(EVENTS.FEIM_SET_TURBO, this.handleTurboButtonClick);
        });
    }
    @Watch('autoplayIsActive')
    onAutoplayIsActiveChange () {
        if (this.autoplayIsActive) {
            this.isAutoplayActive = true;
        } else {
            this.isAutoplayActive = false;
        }
    }

    handleAutoButtonClick (): void {
        if (this.autoplayIsActive) {
            this.playSound('ClickButtons');
            CommonEventEmitter.announceStopAutoplayClicked();
            this.isAutoplayActive = false;
            return;
        }

        if (!this.controlsIsDisabled) {
            this.playSound('ClickButtons');
            this.$store.dispatch('popup/showAutoplayModal');
        }
    }

    handleTurboButtonClick () {
        if (!this.controlsIsDisabled || this.isAutoplayActive) {
            this.playSound('ClickButtons');
            this.$store.dispatch('turboSpinButton/toggleActive');
            CommonEventEmitter.announceTurboButtonChanged(this.turboButtonIsActive);
        }
    }

    handleQuitButtonClick () {
        if (window.confirm('Do you really want to leave?')) {
            // TODO: Add close page URL
            // For example - https://public.pg-demo.com/pages/close.html
            window.open('/', '_parent', '');
            window.close();
        }
    }

    get controlsIsDisabled (): boolean {
        return this.$store.state.controlsIsDisabled;
    }

    get turboButtonIsVisible (): boolean {
        return this.$store.state.turboSpinButton.isVisible;
    }

    get turboButtonIsActive (): boolean {
        return this.$store.state.turboSpinButton.isActive;
    }

    get autoplayIsActive (): boolean {
        return this.$store.state.autoplay.isActive;
    }

    get autoplayDisable (): boolean {
        return this.$store.state.autoplay.autoplayDisable;
    }

    get autoplaySpinsLeft (): number | string {
        if (window.autoplayInfinite && this.$store.state.autoplay.spinsLeft > 1000) {
            return '∞';
        }
        return this.$store.state.autoplay.spinsLeft - 1;
    }
    get isMobile (): boolean {
        return window.deviceInfo.isMobile;
    }
}
