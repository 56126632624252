
import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';

@Component({})
export default class FeaturesSection extends Vue {
    private marg: string = this.lang === 'en_US' || this.lang === 'en_GB' ? '-5px 0' : '-54px 0';
    private pickMeChestImage: string = `./assets/common/pick_me/chest_normal.png`;
    private pickMeTextImage: string = `./assets/${this.lang}/pick_me/texts/pick_a_chest.png`;

    get bonusWheelPng (): string {
        if (this.lang === 'sv_SE') {
            return `./assets/${this.lang}/paytable/texts/bonus_wheel_se.png`;
        } else if (this.lang === 'da_DK') {
            return `./assets/${this.lang}/paytable/texts/bonus_wheel_dk.png`;
        } else if (this.lang === 'ro_RO') {
            return `./assets/${this.lang}/paytable/texts/bonus_wheel_ro.png`;
        } else {
            return './assets/common/paytable/bonus_wheel.png';
        }
    }
    get lang (): string {
        return window.lang;
    }
}
