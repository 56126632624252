
import { Component, Mixins } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS } from '../../../config/constants';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';
import CommonEventEmitter from '../../../event-emitter/CommonEventEmitter';

@Component({
    components: {}
})
export default class GameUnfinished extends Mixins(GlobalMixin) {
    wid: string = window.deviceInfo.isMobile ? '60%' : '46%';
    amount = localStorage.getItem('restoreState');
    continueBtn: string = `./assets/${window.lang}/ui/texts/ui_continue.png`;
    // TODO
    gameUnfinished: string = `./assets/${window.lang}/settings/texts/settings_unfinished.png`;
    wonAmount: string = `./assets/${window.lang}/settings/texts/settings_unfinished_win.png`;
    goodLuck: string = `./assets/${window.lang}/settings/texts/settings_unfinished_nowin.png`

    handleGameUnfinished () {
        this.$store.dispatch('popup/hideGameUnfinishedPopup');
        this.playSound('ClickButtons');
        CommonEventEmitter.announceRestoreGame();
    }
}
