
import { Component, Vue } from 'vue-property-decorator';
import { PromotionalFreeSpinsActions } from './contracts/PromotionalFreeSpinsActions';
import { EventBus } from './../../utils/EventBus';
import { EVENTS } from '../../config/constants';

import BetSizeOptions from './BetSizeOptions.vue';
import CommonEventEmitter from '../../event-emitter/CommonEventEmitter';
import { IPromotionalFreeSpins } from '../../store/modules/slot/contracts/promotionalFreeSpins';

@Component({
    components: {
        BetSizeOptions
    }
})
export default class PromotionalFreeSpins extends Vue {
    wid: string = window.deviceInfo.isMobile ? '60%' : '46%';
    fsCongrats: string = `./assets/${window.lang}/freerounds/texts/freerounds_introText.png`;
    fsOptions: string = `./assets/${window.lang}/freerounds/texts/freerounds_introText2.png`;
    fsUnfinished: string = `./assets/${window.lang}/freerounds/texts/freerounds_unfinished.png`;
    fsContinueBtn: string = `./assets/${window.lang}/ui/texts/ui_continue.png`;
    fsSkipBtn: string = `./assets/${window.lang}/ui/texts/ui_play_later.png`;
    fsDeleteBtn: string = `./assets/${window.lang}/ui/texts/ui_no_thanks.png`;
    fsExpired: string = `./assets/${window.lang}/freerounds/texts/freerounds_expired.png`;
    hasExpired: boolean = this.$store.state.promotionalFreeSpins.promotionalFreeSpins.expires * 1000 < Date.now();
    expireDate: string = new Date(this.$store.state.promotionalFreeSpins.promotionalFreeSpins.expires).toLocaleString();
    handlePromotionalFreeSpinsAccept () {
        const spins = this.$store.state.promotionalFreeSpins.selectedBetSize ? this.$store.state.promotionalFreeSpins.selectedBetSize.spins : this.$store.state.promotionalFreeSpins.promotionalFreeSpins.spins;
        const betAmount = this.$store.state.promotionalFreeSpins.selectedBetSize ? this.$store.state.promotionalFreeSpins.selectedBetSize.ba : this.$store.state.promotionalFreeSpins.promotionalFreeSpins.ba;
        CommonEventEmitter.announceStakeChanged({ stake: betAmount });
        CommonEventEmitter.announcePromotionalFreeSpinsStarting(
            PromotionalFreeSpinsActions.CONTINUE,
            this.$store.state.promotionalFreeSpins.promotionalFreeSpins.autoplay,
            spins,
            betAmount
        );
    }

    handlePromotionalFreeSpinsSkip () {
        CommonEventEmitter.announcePromotionalFreeSpinsStarting(
            PromotionalFreeSpinsActions.SKIP
        );
    }
    handlePromotionalFreeSpinsDelete () {
        EventBus.$emit(EVENTS.PROMOTIONAL_FREE_SPINS,
            {
                type: EVENTS.PROMOTIONAL_FREE_SPINS,
                action: PromotionalFreeSpinsActions.DELETE
            }
        );
    }

    get promotionalFreeSpins (): IPromotionalFreeSpins | null {
        return this.$store.state.promotionalFreeSpins.promotionalFreeSpins;
    }
}
