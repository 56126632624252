import { render, staticRenderFns } from "./PaytableFeatures.vue?vue&type=template&id=1be9946a&scoped=true"
import script from "./PaytableFeatures.vue?vue&type=script&lang=ts"
export * from "./PaytableFeatures.vue?vue&type=script&lang=ts"
import style0 from "./PaytableFeatures.vue?vue&type=style&index=0&id=1be9946a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be9946a",
  null
  
)

export default component.exports